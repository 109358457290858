@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 96.1%;
    --card-foreground: 0 0% 45.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 89.8%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 14.9%;
    --card-foreground: 0 0% 63.9%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 14.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  .stacking-context {
    isolation: isolate;
  }
  .chat-container {
    max-height: 50vh;
    overflow-y: auto;
  }
  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@layer components {
  .tab-trigger {
    @apply bg-white/20 text-white font-semibold py-2 px-3 rounded-full transition-all duration-300 ease-in-out text-xs sm:text-sm;
  }

  .tab-trigger[data-state="active"] {
    @apply bg-white text-blue-600 shadow-lg transform scale-105;
  }

  .tab-trigger:hover {
    @apply bg-white/40 transform scale-105;
  }

  .light .tab-trigger {
    @apply bg-blue-500/20 text-blue-700;
  }

  .light .tab-trigger[data-state="active"] {
    @apply bg-blue-500 text-white;
  }

  .light .tab-trigger:hover {
    @apply bg-blue-500/40;
  }

  .dark .tab-trigger {
    @apply bg-gray-800/30 text-gray-200;
  }

  .dark .tab-trigger[data-state="active"] {
    @apply bg-gray-800 text-blue-400;
  }

  .dark .tab-trigger:hover {
    @apply bg-gray-800/50;
  }
}

@media (max-width: 640px) {
  .tab-trigger {
    @apply text-xs py-1 px-2;
  }
}

@media (max-width: 1023px) {
  .order-1 {
    order: -1;
  }
  .order-2 {
    order: initial;
  }
}
